import React, { useState, useEffect, useRef } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "jquery/dist/jquery.min.js";
// import "bootstrap/dist/js/bootstrap.min.js";
// import Logo from "../../assets/Tag8-Logo-slst--black.png";
import "./header.component.css";
import { Link } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import jwt_decode from "jwt-decode";
import { Button } from "react-bootstrap";
// import qrcode from "../../assets/qr-code.png";
import { QrReader } from "react-qr-reader";
// import UserImage from "../../assets/user.png";
import * as BiIcons from "react-icons/bi";
import { BiX } from "react-icons/bi";
import { switchUrls } from "../../api/index.js";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import * as RiIcons from "react-icons/ri";
import { DialogTitle, DialogActions } from "@material-ui/core";

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");

const delete_cookie = (name) => {
  const domain = window.location.hostname.includes("localhost")
    ? "localhost"
    : window.location.hostname.includes("tag8.co.in")
    ? ".tag8.co.in"
    : window.location.hostname.includes("tag8.in")
    ? ".tag8.in"
    : "";

  if (domain) {
    document.cookie = `${name}=; Path=/; domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  } else {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
};

function Navbar({ setName }) {
  const [isAuth, setIsAuth] = useState(usertoken !== null ? true : false);
  const [passwordType, setPasswordType] = useState("password");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [productcategory, setProductcategory] = useState("");
  const [tagNumber, setTagNumber] = useState("");
  const [form, setForm] = useState("none");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [uid, setUID] = useState("");
  const [ownerID, setOwnerID] = useState("");
  const [tagNotFound, setTagNotFound] = useState(false);
  const [alreadyActivated, setAlreadyActivated] = useState(false);
  const [show, setShow] = useState(false);
  const [ok, setOk] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [characterLength, setCharacterLength] = useState("none");
  const [validPassword, setValidPassword] = useState("none");
  const [passwordValid, setPasswordValid] = useState(false);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("none");
  const [found, setFound] = useState(false);
  const [scan, setScan] = useState(false);
  const [show5, setShow5] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [parentImage, setParentImage] = useState();
  const [dialog, setDialog] = useState(false);
  const [loginbtn, setLoginBtn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isBorderFixed, setIsBorderFixed] = useState(false);
  // const [ip, setIP] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const wrapperRef = useRef();
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const baseURL = switchUrls("gateway");

  const token = usertoken;

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleClosebtn = () => setLoginBtn(true);
  const buttonClose = () => {
    setDialog(false);
    setShow(false);
  };

  useEffect(() => {
    const header = document.querySelector(".header-section");
    const announcementBar = document.querySelector(".announcement-bar-1");
    const logoContainer = document.querySelector(".logo-container");
    const navbarToggleBtn = document.querySelector(".navbar-toggle-btn");

    const extraHeight = 40;
    const maxScreenWidth = 767;
    const scrollThreshold = 5;
    let animationFrameId;

    const handleScroll = () => {
      cancelAnimationFrame(animationFrameId);

      animationFrameId = requestAnimationFrame(() => {
        const currentScrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const isSmallScreen = window.innerWidth <= maxScreenWidth;

        const scrollDifference = Math.abs(currentScrollTop - lastScrollTop);

        if (scrollDifference > scrollThreshold) {
          if (currentScrollTop > lastScrollTop) {
            // Scrolling down
            if (!isSmallScreen) {
              header.classList.add("header-sticky");
              announcementBar.classList.add("announcement-bar-1-hidden");
            }
            if (isSmallScreen) {
              logoContainer.style.display = "block";
              navbarToggleBtn.style.display = "block";
            }
            header.style.position = "fixed";
            header.style.height = `-${extraHeight}px`;
          } else if (currentScrollTop < lastScrollTop) {
            // Scrolling up
            header.classList.remove("header-sticky");
            if (currentScrollTop === 0) {
              if (!isSmallScreen) {
                announcementBar.classList.remove("announcement-bar-1-hidden");
              }
              if (isSmallScreen) {
                logoContainer.style.display = "block";
                navbarToggleBtn.style.display = "block";
              }
            }
          }

          setLastScrollTop(currentScrollTop);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      cancelAnimationFrame(animationFrameId);
    };
  }, [lastScrollTop]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
  const handleNotFound = () => setTagNotFound(false);
  const handleActivated = () => {
    setAlreadyActivated(false);
    window.location.href = "/";
  };

  const passwordRE = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }
  useEffect(() => {
    if (tagNumber) {
      axios
        .get("/api/getTagNumber", {
          params: {
            tag: tagNumber,
          },
        })
        .then((res) => {
          var data = res.data.tagResult.product_category;
          // console.log(data, "resshhshh");
          setProductcategory(data);
          // showinitialletter(data);
        })
        .catch((error) => {});
    }
  }, []);

const getData = async () => {
  try {
    const ipApiResponse = await fetch("https://ipapi.co/json/");
    if (!ipApiResponse.ok) {
      throw new Error("Failed to fetch IP data");
    }

    const countryData = await ipApiResponse.json();
    const ipAddress = countryData?.country;

    console.log(ipAddress, "country detected");

    // Determine if the country is India
    const isIndianIP = ipAddress === "IN";

    // Set URLs for `.in` (India) and `.co` (Outside India)
    const homeUrl = isIndianIP ? "https://home.tag8.in/" : "https://home.tag8.co/";
    const productsUrl = isIndianIP
      ? "https://home.tag8.in/collections"
      : "https://home.tag8.co/collections";
    const corporateUrl = isIndianIP
      ? "https://home.tag8.in/pages/corporate"
      : "https://home.tag8.co/pages/contact";
    const aboutUsUrl = isIndianIP
      ? "https://home.tag8.in/pages/about-us"
      : "https://home.tag8.co/pages/about-us";
    const contactUsUrl = isIndianIP
      ? "https://home.tag8.in/pages/contact"
      : "https://home.tag8.co/pages/copy-of-contact";

    // Update links dynamically based on detected country
    const updateLink = (id, url) => {
      const linkElement = document.getElementById(id);
      if (linkElement) {
        linkElement.href = url;
      }
    };

    updateLink("homeLink", homeUrl);
    updateLink("productsLink", productsUrl);
    updateLink("corporateLink", corporateUrl);
    updateLink("aboutUsLink", aboutUsUrl);
    updateLink("contactUsLink", contactUsUrl);

  } catch (error) {
    console.error("Error fetching IP data:", error.message);

    // If there's an error, default to the `.in` domain (India)
    const fallbackDomain = "https://home.tag8.in";
    const homeUrl = `${fallbackDomain}/`;
    const productsUrl = `${fallbackDomain}/collections`;
    const corporateUrl = `${fallbackDomain}/pages/corporate`;
    const aboutUsUrl = `${fallbackDomain}/pages/about-us`;
    const contactUsUrl = `${fallbackDomain}/pages/contact`;

    // Update all links to the `.in` domain as the fallback
    const updateLink = (id, url) => {
      const linkElement = document.getElementById(id);
      if (linkElement) {
        linkElement.href = url;
      }
    };

    updateLink("homeLink", homeUrl);
    updateLink("productsLink", productsUrl);
    updateLink("corporateLink", corporateUrl);
    updateLink("aboutUsLink", aboutUsUrl);
    updateLink("contactUsLink", contactUsUrl);
  }
};
 
  const renderQRModal = () => {
    return (
      <Dialog
        open={show5}
        fullWidth
        maxWidth="xs"
        onClose={() => setShow5(false)}
        aria-labelledby={"Scan QR"}
      >
        <div>
          <QrReader
            onResult={(result, error) => {
              if (result) {
                setTagNumber(
                  result?.text.split("/")[result?.text.split("/").length - 1]
                );
                setShow5(false);
              }

              if (error) {
                console.error(error.message);
              }
            }}
            style={{ width: "100%" }}
          />
        </div>
      </Dialog>
    );
  };

  function activateTag() {
    if (isAuth) {
      const state = {
        tag_number: tagNumber.toUpperCase(),
      };

      axios
        .post(`${baseURL}/api/checkTagNumberWithoutHeader`, state, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const parentImg = localStorage.getItem("parent_image");

          setParentImage(parentImg);

          if (res.data.msg === "Tag not Found by the specified Number") {
            setShow(false);
            setTagNotFound(true);
          } else if (res.data.msg === "Tag is already activated") {
            setShow(false);
            setAlreadyActivated(true);
          } else {
            const initial = tagNumber.substring(0, 3);

            if (productcategory === "QR PET") {
              window.location.href = "/pet-activation/" + tagNumber;
            } else if (productcategory === "QR Key") {
              window.location.href = "/key-activation/" + tagNumber;
            } else if (
              productcategory === "QR bag" ||
              productcategory === "QR Bag" ||
              productcategory === "Dolphin Bag"
            ) {
              window.location.href = "/bag-activation/" + tagNumber;
            } else if (productcategory === "QR Passport") {
              window.location.href = "/passport-activation/" + tagNumber;
            } else if (productcategory === "QR Gadget") {
              window.location.href = "/earphone-activation/" + tagNumber;
            } else if (productcategory === "Generic") {
              window.location.href = "/generic-activation/" + tagNumber;
            } else if (productcategory === "QR Wallet") {
              window.location.href = "/wallet-activation/" + tagNumber;
            }
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    } else {
      setDialog(true);
      const state = {
        tag_number: tagNumber.toUpperCase(),
      };
      localStorage.setItem("tagNumber", tagNumber);
      axios
        .post("/api/checkTagNumberWithoutHeader", state, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const parentImg = localStorage.getItem("parent_image");

          setParentImage(parentImg);

          if (res.data.msg === "Tag not Found by the specified Number") {
            setShow(false);
            setTagNotFound(true);
          } else if (
            res.data.msg === "Tag is already activated" ||
            alreadyActivated == true
          ) {
            // console.log("else");
            setShow(false);
            setAlreadyActivated(true);
            setScan(true);
            setDialog(false);
            // console.log("active else", scan);
            localStorage.setItem("active", alreadyActivated);
            localStorage.setItem("scan", scan);
            localStorage.removeItem("tagNumber");
            // localStorage.removeItem("tag_number");
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }

  function reportFound() {
    window.location.href = "/#/scan/" + tagNumber;
    setFound(false);
    localStorage.setItem("Found", found);
  }

  function logout(event) {
    delete_cookie("token");
    delete_cookie("fromLogin");
    // window.location.reload();
    window.location.href = `${baseURL}/#/login`;
    setIsAuth(false);
  }

  function logoutgoogle() {
    googleLogout();
    delete_cookie("token");
    delete_cookie("fromLogin");
    localStorage.clear();
    window.location.href = `${baseURL}/#/login`;
  }
  function updatePassword() {
    const state = {
      password: password,
      email: email,
    };

    axios
      .post("/api/updatePassword", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.data === "Password updated successfully") {
          setChangePassword(false);
          setMessage("Password updated succesfully");
          setAlert("flex");
          setPassword("");
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  useEffect(() => {
    if (isAuth) {
      axios
        .get(`${baseURL}/api/getUserProfile`, {
          headers: {
            Authorization: usertoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          localStorage.setItem("uid", res.data.uid);

          setUID(res.data.uid);
          setFirstName(res.data.first_name);

          if (res.data.last_name === undefined) {
            localStorage.setItem("name", capitalizeName(res.data.first_name));
          } else {
            setLastName(res.data.last_name);
const fetchedFirstName = res.data.first_name || "";
          const fetchedLastName = res.data.last_name || "";
            // localStorage.setItem(
            //   "name",
            //   capitalizeName(res.data.first_name + " " + res.data.last_name)
            // );
            setName(`${fetchedFirstName} ${fetchedLastName}`);
          }

          setEmail(res.data.email);
        })
        .catch((error) => {
          console.error(error.message);
        });
    }

    getData();
  }, []);

  function formValid() {
    return passwordValid;
  }

  useEffect(() => {
    if (password.length > 7 || password === "") {
      setCharacterLength("none");

      if (passwordRE.test(password)) {
        setPasswordValid(true);
        setValidPassword("none");
      } else if (password !== "") {
        setPasswordValid(false);
        setValidPassword("block");
      } else {
        setPasswordValid(false);
        setValidPassword("none");
      }
    } else {
      setValidPassword("block");
      setCharacterLength("block");
      setPasswordValid(false);
    }

    setSubmitDisabled(!formValid());
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, []);

  const valid = (item) => {
    let password = document.querySelector(`#${item}`);

    password.style.display = "none";
  };

  //If Form Not Valid
  const invalid = (item) => {
    let password = document.querySelector(`#${item}`);

    password.style.display = "block";
  };

  useEffect(() => {
    // const handleScroll = () => {
    //   console.log("Scrolling...");
    //   const rect = wrapperRef.current.getBoundingClientRect();
    //   setIsScrolled(rect.top < 0);
    // };
    // window.addEventListener("scroll", handleScroll);
    // return () => {
    //   console.log("Removing event listener...");
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  useEffect(() => {
    if (usertoken) {
      setInterval(() => {
        var decoded = jwt_decode(usertoken);
        const expiry = decoded.exp * 1000 - 60000;

        if (Date.now() >= expiry) {
          delete_cookie("token");
          delete_cookie("fromLogin");

          window.location.href = `${baseURL}/#/login`;
        }
      }, 1000);
    }
  });

  const showNavbar = (e) => {
    e.preventDefault();

    setNavbar(!navbar);
  };

  return (
    <>
      <div className=" announcement-bar-1" id="announcement-bar">
        <div className="announcement-bar__wrapper" ref={wrapperRef}>
          <div className="announcement-bar__message">
            <div className="announcement-bar__text">
              <p>
                <strong>Click to Activate or Report Found tag8! </strong>
                <a onClick={handleShow}>
                  <strong>Click Here</strong>
                </a>
              </p>
            </div>
          </div>
          {isBorderFixed && <div className="announcement-bar__border" />}
        </div>
      </div>
      <header className="header-section">
        <div>
          <div class="logo-container">
            <a className="logo" href="https://dashboard.tag8.in/">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/assets/Tag8-Logo-slst--black.png"
                }
                alt="logo"
                loading="lazy"
                className="logo-image"
              />
            </a>
          </div>
        </div>

        <button
          className="navbar-toggle-btn"
          aria-controls="navbarSupportedContent"
          aria-label="Toggle navigation"
          ref={buttonRef}
          onClick={showNavbar}
        >
          {navbar ? <BiX /> : <HiOutlineMenuAlt1 />}
        </button>

        <nav
          className={navbar ? "header-nav" : "header-nav-hide"}
          id="navbarSupportedContent"
        >
          {/* {navbar ? (
            <button className="close-button" onClick={showNavbar}>
              <BiX />
            </button>
          ) : null} */}
          <ul className="header-nav-ul">
            <li className="header-nav-ul-li">
              {/* {document.referrer === "/key" ? (
                <a href="/key" className="nav-link navbar__options">
                  Home
                </a>
              ) : (
                <a href="/pet" className="nav-link navbar__options">
                  Home
                </a>
              )} */}
              <a id="homeLink" href="/#" className="nav-link navbar__options">
                Home
              </a>
            </li>

            <li className="header-nav-ul-li">
              {/* {document.referrer === "/key" ? (
                <a
                  href="https://tag8.in/tag8-store-new/public/tag8-faq/index.html"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link navbar__options"
                >
                  FAQ
                </a>
              ) : (
                <a href="/faq" className="nav-link navbar__options">
                  FAQ
                </a>
              )} */}
              <a
                id="productsLink"
                href="/#"
                target="_blank"
                rel="noreferrer"
                className="nav-link navbar__options"
              >
                Products
              </a>
            </li>

            <li className="header-nav-ul-li">
              <a
                id="corporateLink"
                className="nav-link navbar__options"
                target="_blank"
                rel="noreferrer"
                href="/#"
              >
                Corporate
              </a>
            </li>

            <li className="header-nav-ul-li">
              <a
                id="aboutUsLink"
                className="nav-link navbar__options"
                target="_blank"
                rel="noreferrer"
                href="/#"
              >
                About Us
              </a>
            </li>
            <li className="header-nav-ul-li">
              <a
                id="contactUsLink"
                className="nav-link navbar__options"
                target="_blank"
                rel="noreferrer"
                href="/#"
              >
                Contact Us
              </a>
            </li>
            <li className="header-nav-ul-li">
              {isAuth ? (
              <button
                  className="nav-link navbar__options"
                  onClick={() => (window.location.href = `${baseURL}/#/dashboard/${uid}`)}
                >
                  My Tags
                </button>
              ) : (
                <button
                  className="nav-link navbar__options"
                  onClick={() => (window.location.href = `${baseURL}/#/login`)}
                >
                  Login
                </button> 
              )}
            </li>

            <li className="profile-header-nav-ul-li">
              {!isAuth ? (
                 <button 
                className="nav-link navbar__options"
                onClick={() => window.location.href = `${baseURL}/#/signup`}
              >
                Signup
              </button>
              ) : (
                <div ref={dropdownRef} class="dropdown">
                  <button
                    class="btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={toggleDropdown}
                  >
                    <a
                      to="#"
                      className="btn navbar__options"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <img
                          src={
                            parentImage
                              ? "parentImage"
                              : "https://storage.googleapis.com/pettag/qr/assets/user.png"
                          }
                          alt="user"
                          className="user-image"
                          loading="lazy"
                          style={{ width: "2.2rem" }}
                        />

                        {capitalizeName(firstName + " " + lastName)}

                        <i
                          className="fa fa-caret-down ml-2"
                          aria-hidden="true"
                          onClick={handleClosebtn}
                        ></i>
                      </span>
                    </a>
                  </button>
                  <ul
                    // className="dropdown-menu"
                    class={`dropdown-menu${isOpen ? " show" : ""}`}
                    style={{
                      minWidth: "14rem",
                      margin: "0.2rem 0.5rem 0rem",
                      padding: "0rem 0rem",
                      borderRadius: "1.20em",
                    }}
                  >
                    <div className="dropdown-content-1">
                      <a
                        href={`${baseURL}/#/profile`}
                        className="dropdown-item"
                      >
                        <center className="drop_down_nav">Profile</center>
                      </a>

                      {localStorage.getItem("logintype") === "manual" ? (
                        <Link
                          to="#"
                          onClick={() => setChangePassword(true)}
                          className="dropdown-item"
                        >
                          <center className="drop_down_nav">
                            Change Password
                          </center>
                        </Link>
                      ) : (
                        ""
                      )}
                      <a
                        onClick={
                          localStorage.getItem("logintype") === "manual"
                            ? logout
                            : logoutgoogle
                        }
                        href={`${baseURL}/#/login`}
                        className="dropdown-item"
                      >
                        <center className="drop_down_nav">Logout</center>
                      </a>
                    </div>
                  </ul>
                </div>
              )}
            </li>

            <li className="header-nav-ul-li">
              {!isAuth ? (
                <div className="got-genie-form" style={{ display: form }}>
                  <input
                    style={{ padding: "-5px" }}
                    placeholder="Owner ID"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                    }}
                    onChange={(e) => setTagNumber(e.target.value)}
                  />

                  <div className="genie-form-buttons">
                    <Button
                      variant="light"
                      title={
                        !isAuth
                          ? "Please login to activate tag"
                          : "Activate Tag"
                      }
                      onClick={activateTag}
                      disabled={!isAuth}
                    >
                      Activate
                    </Button>

                    <Button variant="light" onClick={reportFound}>
                      Report Found
                    </Button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* <button className="got-genie1" onClick={handleShow}>
                <span className="got-genie-top font-weight-bold">Click To</span>
                <br></br> Report Found
              </button> */}
            </li>
          </ul>

          {/* <div className="got-genie-div-1">
            <button className="got-genie1" onClick={handleShow}>
              <span className="got-genie-top font-weight-bold">Got tag8?</span>
              <br></br> Register or Report
            </button>

            <div className="got-genie-form" style={{ display: form }}>
              <input
                style={{ padding: "-5px" }}
                placeholder="Owner ID"
                onKeyUp={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                }}
                onChange={(e) => setOwnerID(e.target.value)}
              />

              <img
                src={
                  "https://storage.googleapis.com/pettag/qrtagdev/assets/qr-code.png"
                }
                alt="qr code"
                className="qrbutton"
                loading="lazy"
                onClick={(e) => setShow5(true)}
              />

              <div className="genie-form-buttons">
                <Button onClick={reportFound}>Report Found</Button>
                <Button
                  variant="light"
                  title={
                    !isAuth ? "Please login to activate tag" : "Activate Tag"
                  }
                  onClick={activateTag}
                  disabled={!isAuth}
                >
                  Activate
                </Button>
              </div>
            </div>
          </div> */}
        </nav>

        <Dialog
          PaperProps={{
            style: { borderRadius: 15 },
          }}
          open={tagNotFound}
          onClose={handleNotFound}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className="found-notfound-popup">
            <h1
              style={{
                margin: "20px",
                font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                letterSpacing: "normal",
              }}
            >
              Tag number not found
            </h1>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                className="otp-buttons"
                style={{ margin: "10px 20px", marginBottom: "50px" }}
                onClick={handleNotFound}
              >
                OK
              </button>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          PaperProps={{
            style: { borderRadius: 15 },
          }}
          open={alreadyActivated}
          onClose={handleActivated}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className="found-notfound-popup">
            <h1
              style={{
                margin: "20px",
                font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                letterSpacing: "normal",
              }}
            >
              Tag is already activated
            </h1>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                className="otp-buttons"
                style={{ margin: "10px 20px", marginBottom: "50px" }}
                onClick={handleActivated}
              >
                OK
              </button>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          PaperProps={{
            style: { borderRadius: 15 },
          }}
          maxWidth="xs"
          open={changePassword}
          onClose={() => setChangePassword(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className="header-change-password-popup">
            <label>Change Password</label>

            <div className="header-change-password-popup-input-container">
              <input
                className="header-change-password-popup-input"
                value={email}
                readOnly
              />

              <span className="header-change-password-popup-checkbox-input-container">
                <input
                  className="header-change-password-popup-input"
                  type={passwordType}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);

                    const txt = e.target.value;

                    if (txt.length >= 8) {
                      valid("islen");
                    } else {
                      invalid("islen");
                    }

                    if (txt.match(/[A-Z]/) != null) {
                      valid("capital");
                    } else {
                      invalid("capital");
                    }

                    if (txt.match(/[a-z]/) != null) {
                      valid("small");
                    } else {
                      invalid("small");
                    }

                    if (txt.match(/[0-9]/) != null) {
                      valid("isnum");
                    } else {
                      invalid("isnum");
                    }
                  }}
                  required
                />

                <div>
                  <span style={{ display: characterLength }} id="islen">
                    Password must be atleat 8 characters
                  </span>

                  <span style={{ display: validPassword }} id="issp">
                    Password must contain -{" "}
                  </span>

                  <span style={{ display: validPassword }} id="isnum">
                    Atleast one number
                  </span>

                  <span style={{ display: validPassword }} id="capital">
                    Atleast one uppercase letter
                  </span>

                  <span style={{ display: validPassword }} id="small">
                    Atleast one lowercase letter
                  </span>
                </div>

                <div className="header-change-password-popup-checkbox-container">
                  <input
                    id="header-change-password-checkbox"
                    type="checkbox"
                    className="header-change-password-popup-checkbox"
                    onClick={() =>
                      setPasswordType(
                        passwordType === "password" ? "text" : "password"
                      )
                    }
                  />

                  <label htmlFor="header-change-password-checkbox">
                    <RiIcons.RiLockPasswordFill />
                  </label>
                </div>
              </span>
            </div>

            <div className="header-change-password-popup-btns">
              <button
                disabled={submitDisabled}
                onClick={updatePassword}
                style={{ backgroundColor: "#ffc107" }}
              >
                Submit
              </button>

              <button
                onClick={() => {
                  setChangePassword(false);
                  setEmail("");
                  setPassword("");
                }}
              >
                Cancel
              </button>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          PaperProps={{
            style: { borderRadius: 15, position: "relative" },
          }}
          open={show}
          fullWidth
          maxWidth="xs"
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <h2 className="header-activateTag-popup">Tag Report Found</h2>
          </DialogContent>
          <IconButton
            style={{ position: "absolute", top: 0, right: "10px" }}
            onClick={handleClose}
          >
            <RiIcons.RiCloseLine />
          </IconButton>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5em",
            }}
          >
            <img
              src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
              style={{ width: "30px", height: "30px" }}
            />
            <input
              className="header-input__field__dialogs"
              onKeyUp={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              value={tagNumber ? tagNumber : ""}
              onChange={(e) => setTagNumber(e.target.value.toUpperCase())}
              placeholder="Owner ID"
            />

            {/* <img
              src={
                "https://storage.googleapis.com/pettag/qrtagdev/assets/qr-code.png"
              }
              className="qrbutton"
              loading="lazy"
              onClick={(e) => setShow5(true)}
              alt="qr code"
            /> */}
          </div>

          <div
            style={{
              padding: "1em",
              marginBottom: "1px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            {/* <button className="header-verifyOtp-button" onClick={activateTag}>
              Activate
            </button> */}
            <button className="header-verifyOtp-button" onClick={reportFound}>
              Found
            </button>
          </div>
        </Dialog>

        <Dialog
          PaperProps={{
            style: { borderRadius: 15 },
          }}
          fullWidth
          maxWidth="xs"
          aria-labelledby="form-dialog-title"
          open={dialog}
          onClose={() => setDialog(!dialog)}
        >
          <DialogTitle>
            Inorder to Activate/Report Tag, kindly first login.
          </DialogTitle>

          <DialogActions style={{ margin: "0px 1em 1em 1em" }}>
            <button id="submit__button" onClick={buttonClose}>
              LogIn/Signup
            </button>
          </DialogActions>
        </Dialog>

        <div className="AlertSignup" style={{ display: alert }}>
          <p>{message}</p>

          <button onClick={() => setAlert("none")}>Cancel</button>
        </div>

        {renderQRModal()}
      </header>
    </>
  );
}

export default Navbar;
